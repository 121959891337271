<template lang="pug">
  include ../../../assets/pug/index
  .mt-8(v-if="user")
    ul
      li
        +b('fullName')
        span {{ user.first_name }} {{ user.last_name }}
      li
        +b('username') 
        span {{user.username}}
        //- span.
        //-   {{ $isEval('user?.userprofile?.address?.postal_index') }}
        //-   {{ $isEval('user?.userprofile?.address?.locality_name') }}
        //-   {{ $isEval('user?.userprofile?.address?.city') }}
        //-   {{ $isEval('user?.userprofile?.address?.street') }}
        //-   {{ $isEval('user?.userprofile?.address?.house') }}
        //-   {{ $t('flat') }} {{ $isEval('user?.userprofile?.address?.flat') }}
      li
        //- +b('phoneNumber')
        //- span {{ $isEval('user?.userprofile?.phone') }}
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'EntrepreneurInfo',
  props: {
    userObj: { type: Object, default: () => {}}
  },
 
  computed: {
    ...mapState({
      user: state => state.user.user
    })
    // user () {
    //   let user = ''
    //   if (this.userObj && Object.keys(this.userObj).length) user = this.userObj
    //   else user = this.$store.state.user.user
    //   return user
    // }
  }
}
</script>
