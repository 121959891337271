<template lang="pug">
  include ../../../assets/pug/index
  .mt-8
    .d-flex
      +inputValidation('userObj.last_name', 'lastName', '["required"]').mr-4
      +inputValidation('userObj.first_name', 'firstName', '["required"]').mr-4
      +inputValidation('userObj.username', 'username', '["required"]')
    .d-flex.justify-end.w-100(v-if="viewBtnAdd")
      +btn('success', 'save')(@click="validation()" :loading="isLoad")
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { validate } from '@/mixins/validation'

export default {
  name: 'EntrepreneurForm',
  props: {
    viewBtnAdd: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isLoad: false,
      isRegionLoad: false,
      userObj: {
        first_name: null,
        last_name: null,
        username: null,
      }
    }
  },
  validations: validate.entrepreneur,
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  mounted () {
    this.userObj = this.user
  },
  methods: {

    ...mapActions(['updateUser']),

    validationField (field, type) {
      let messages = []
      type.forEach(item => messages.push(...this.$validate[item](field)))
      return messages
    },
    validation () {
      this.$v.$touch()
      !this.$v.$invalid && this.saveUser()
    },
    saveUser () {
      this.isLoad = true
      this.userObj.user_id = this.user.uid
      this.updateUser(this.userObj)
      .then((r) => r === 'success' && this.$notification.success(this.$i18n.t('notify.changedUser')))
        .finally(() => {
          this.isLoad = false
          this.$parent.viewForm = false
      })
    },
  }
}
</script>
