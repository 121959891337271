<template lang="pug">
  div
    .d-flex.justify-space-between
      h3 {{ $t('myData') }}
      v-btn(@click="viewForm = !viewForm" icon)
        v-icon {{ viewForm ? 'mdi-close' : 'mdi-pen' }}
    div
      EntrepreneurForm(v-if="viewForm")
      EntrepreneurInfo(v-else)
</template>

<script>
import { mapState } from 'vuex'
import EntrepreneurInfo from '@/components/molecules/Entrepreneur/EntrepreneurInfo'
import EntrepreneurForm from '@/components/molecules/Entrepreneur/EntrepreneurForm'

export default {
  name: 'UserProfile',
  components: {
    EntrepreneurInfo,
    EntrepreneurForm,
  },
  data () {
    return {
      viewForm: false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.user
    })
  }
}
</script>
