<template lang="pug">
  include ../../assets/pug/index
  .subPage
    .subPage-container
      .subPage-container__content
        .content--item
          UserProfile
      .subPage-container__sideBar
        ChangePassword
        Language

</template>

<script>
import  Language from '@/components/atoms/Language'
import UserProfile from '@/components/modules/UserProfile'

import ChangePassword from './_ChangePassword'

export default {
  name: 'Settings',
  components: {
    Language,
    UserProfile,
    ChangePassword
  },
  mounted () {
  this.$store.commit('setDataTitle', this.$i18n.t('settings'))
},
}
</script>
