<template lang="pug">
  include ../../assets/pug/index
  .sideBar--item
    v-dialog(
      v-model="dialog"
      persistent
      max-width="400px")
      template(v-slot:activator="{ on, attrs }")
        +btn('primary', 'btn.changePassword')(v-bind="attrs" v-on="on")
      v-card.p-2
        +h3('btn.changePassword')
        .formColumn
          .mt-7
            +inputValidation('passObj.current_password', 'oldPassword', '["required", "length"]')(
              :type="isShowOld ? 'text' : 'password'"
              :append-icon="isShowOld ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="isShowOld = !isShowOld"
              ).mb-5
            +inputValidation('passObj.new_password1', 'newPassword', '["required", "length"]')(
              :type="isShow1 ? 'text' : 'password'"
              :append-icon="isShow1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="isShow1 = !isShow1"
              ).mb-5
            +inputValidation('passObj.new_password2', 'passConfirm', '["required", "sameAs", "length"]')(
              :type="isShow2 ? 'text' : 'password'"
              :append-icon="isShow2 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="isShow2 = !isShow2"
              )
          .d-flex.justify-end
            +btn('link', 'btn.cancel')(@click="closeModal()")
            +btn('success', 'btn.save')(@click="validation()")
</template>

<script>
import { validate, validationField } from "../../mixins/validation"

function formFieldsInitialState () {
  return {
    current_password: '',
    new_password1: '',
    new_password2: '',
  }
}

export default {
  name: 'changePassword',
  data () {
    return {
      dialog: false,
      passObj: formFieldsInitialState(),
      isShow1: false,
      isShow2: false,
      isShowOld: false,
      validationField
    }
  },
  validations: validate.userRules.passObj,
  methods: {
    validation () {
      this.$v.$touch()
      !this.$v.$invalid && this.changePassword()
    },
    async changePassword () {
      await this.$api.post('user/change_password/', JSON.stringify(this.passObj))
        .then(response => {
          response.data.status_code === 401 ?
          this.$notification.error(this.$i18n.t('passNotChanged')) :
          this.$notification.success(this.$i18n.t('passChanged'))
          this.closeModal()
        })
    },
    closeModal () {
       this.dialog = false
       this.passObj = formFieldsInitialState()
       this.isShow1 = this.isShow2 = this.isShowOld = false,
       this.$v.$reset()
    }
  }
}

</script>