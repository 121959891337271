<template lang="pug">
  include ../../assets/pug/index
  .sideBar--item
    +h3('language')
    .mt-4.d-flex
      div(
        :key="flag.country"
        v-for="flag in flags"
        @click="changeLanguage(flag.language)"
        :class="['cursor-pointer', flag.language === lang ? 'checkedLanguage' : '']")
        country-flag(
          :country='flag.country'
          size='big'
          class="mr-2 pointer")
</template>

<script>
import CountryFlag from 'vue-country-flag'
import { mapState } from 'vuex'

export default {
  name: 'Language',
  components: {
    CountryFlag
  },
  data () {
    return {
      flags: [
        {
          country: 'ua',
          language: 'ua'
        },
        {
          country: 'us',
          language: 'en'
        }
      ],
      
    }
  },
  computed: {
    ...mapState({ lang: state => state.lang})
  },
  methods: {
    changeLanguage (lang) {
      localStorage.setItem('language', lang)
      location.reload()
    }
  }
}
</script>

<style>
  .checkedLanguage {
    border-bottom: 2px solid #e92165;
  }
</style>
